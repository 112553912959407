<route>
{
  "meta": {
    "auth": "sysUserManage"
  }
}
</route>

<template>
  <div class="pageWithTable">
    <bm-cardTabList :list="tabList" current="账号管理"></bm-cardTabList>
    <el-card class="searchContainer">
      <el-form inline>
        <el-form-item label="ID">
          <el-input class="searchInput" v-model.trim="filter.id" placeholder="用户ID" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机">
          <el-input class="searchInput" v-model.trim="filter.mobile" placeholder="手机" clearable></el-input>
        </el-form-item>
        <el-form-item label="真实姓名">
          <el-input class="searchInput" v-model.trim="filter.realName" placeholder="真实姓名" clearable></el-input>
        </el-form-item>
        <el-form-item label="用户昵称">
          <el-input class="searchInput" v-model.trim="filter.userName" placeholder="用户昵称" clearable></el-input>
        </el-form-item>
        <el-form-item label="工号">
          <el-input class="searchInput" v-model.trim="filter.workNumber" placeholder="工号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="info" @click="resetSearch">重置</el-button>
          <el-button type="primary" @click="handleSearch"> 搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>账号列表</span>
        <el-button v-auth="'superAdmin,accountAdd'" @click="handleAdd()" type="success" style="float: right; "
          >添加账号</el-button
        >
      </div>

      <el-table :data="list" v-loading="loading" style="width: 100%" fit>
        <el-table-column align="center" prop="id" label="ID"></el-table-column>
        <el-table-column align="center" prop="avatarUrl" label="头像">
          <template slot-scope="scope">
            <img class="miniAvatar" :src="scope.row.avatarUrl | thumbSmall" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="userName" label="账号"> </el-table-column>
        <el-table-column align="center" prop="workNumber" label="工号"> </el-table-column>

        <el-table-column align="center" prop="realName" label="真实姓名"> </el-table-column>
        <el-table-column align="center" prop="mobile" label="手机号"> </el-table-column>
        <el-table-column align="center" prop="roleName" label="角色"> </el-table-column>
        <el-table-column align="center" prop="remark" label="备注"> </el-table-column>
        <el-table-column align="center" prop="deleteStatus" label="状态">
          <template slot-scope="scope">
            <el-switch
              @change="onStatusChange(scope.row)"
              :value="scope.row.deleteStatus"
              :active-value="0"
              :inactive-value="1"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createTime" label="创建时间">
          <template slot-scope="scope">
            <span>
              {{ scope.row.createTime | formatTime }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="240px" fixed="right">
          <template slot-scope="scope">
            <el-button v-auth="'superAdmin'" @click="handleEditPassword(scope.row)" type="warning" plain size="mini"
              >修改密码</el-button
            >
            <el-button @click="handleEdit(scope.row)" type="primary" plain size="mini">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :close-on-click-modal="false" :visible.sync="isShowEditDialog" :title="form.id ? '编辑' : '新增'">
      <el-form label-width="120px" :model="form" :rules="rules" ref="form">
        <el-form-item v-if="form.id" label="ID">
          <el-input v-model.trim="form.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="账号">
          <el-input v-model.trim="form.userName" :disabled="!!form.id"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名">
          <el-input v-model.trim="form.realName"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="form.mobile" oninput="value=value.replace(/[^0-9-]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="工号">
          <el-input v-model="form.workNumber" oninput="value=value.replace(/[^0-9-]/g,'')"></el-input>
        </el-form-item>
        <template v-if="!form.id">
          <el-form-item label="密码">
            <el-input type="password" v-model.trim="form.password"></el-input>
          </el-form-item>
          <el-form-item label="重复密码">
            <el-input type="password" v-model.trim="form.passwordConfirm"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="头像">
          <template v-if="form.avatarUrl">
            <img class="userAvatar" :src="form.avatarUrl | thumbSmall" @click="handleUploadAvatar" />
          </template>
          <template v-else>
            <div class="uploadIcon" @click="handleUploadAvatar">
              <i class="el-icon-plus"></i>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="form.roleId" :disabled="!$store.getters['user/authList'].includes('superAdmin')">
            <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model.trim="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="isShowEditDialog = false">取消</el-button>
        <el-button round type="primary" @click="handleSubmitForm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :visible.sync="isShowEditPasswordDialog" title="修改密码">
      <el-form label-width="120px" :model="editPasswordForm" ref="form">
        <el-form-item label="ID">
          <el-input v-model="editPasswordForm.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="账号">
          <el-input v-model="editPasswordForm.userName" disabled></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model.trim="editPasswordForm.password"></el-input>
        </el-form-item>
        <el-form-item label="重复密码">
          <el-input type="password" v-model.trim="editPasswordForm.passwordConfirm"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="isShowEditPasswordDialog = false">取消</el-button>
        <el-button round type="primary" @click="handleSubmitEditPassword">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import tabList from '../tabList'
export default {
  mixins: [pagination],
  data() {
    return {
      tabList,
      searchValue: '',
      list: [],
      filter: {
        id: '',
        mobile: '',
        realName: '',
        userName: '',
        workNumber: ''
      },
      isShowEditDialog: false,
      form: {
        id: '',
        workNumber: '',
        mobile: '',
        avatarUrl: '',
        remark: '',
        realName: '',
        userName: '',
        password: '',
        passwordConfirm: '',
        roleId: ''
      },
      isShowEditPasswordDialog: false,
      editPasswordForm: {
        id: 0,
        userName: '',
        password: '',
        passwordConfirm: ''
      },
      rules: {},
      roleList: []
    }
  },
  methods: {
    getRoleList() {
      this.$http
        .get('/boom-center-admin-service/sysAdmin/sysRole', {
          params: {
            size: 100
          }
        })
        .then(res => {
          this.roleList = res.list
        })
    },
    handleUploadAvatar() {
      this.$store
        .dispatch('upload/pickImageList', { maxCount: 1, type: 'user' })
        .then(res => {
          this.form.avatarUrl = res[0]
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    onStatusChange(val) {
      this.$http
        .put('/boom-center-admin-service/sysAdmin/sysUser/updateDeleteStatus', {
          id: val.id,
          deleteStatus: val.deleteStatus ? 0 : 1
        })
        .then(res => {
          this.$message.success('修改状态成功')
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    handleSubmitEditPassword() {
      if (!this.editPasswordForm.id || !this.editPasswordForm.userName) {
        this.$message.error('参数错误')
      } else if (!this.editPasswordForm.password || !this.editPasswordForm.passwordConfirm) {
        this.$message.error('密码不能为空')
      } else if (this.editPasswordForm.password !== this.editPasswordForm.passwordConfirm) {
        this.$message.error('两次输入的密码不一致')
      } else {
        this.$http
          .put('/boom-center-admin-service/sysAdmin/sysUser/updatePwd', this.editPasswordForm)
          .then(res => {
            this.$message.success('修改密码成功')
            this.isShowEditPasswordDialog = false
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
      }
    },
    handleEditPassword(item) {
      this.editPasswordForm.id = item.id
      this.editPasswordForm.userName = item.userName
      this.isShowEditPasswordDialog = true
    },
    resetSearch() {
      this.filter = {
        id: '',
        mobile: '',
        realName: '',
        userName: '',
        workNumber: ''
      }
      this.getList()
    },
    handleSearch() {
      this.currentPage
      this.getList()
    },
    handleAdd() {
      this.form = {
        id: '',
        mobile: '',
        avatarUrl: '',
        remark: '',
        workNumber: '',
        realName: '',
        userName: '',
        password: '',
        passwordConfirm: ''
      }
      this.isShowEditDialog = true
    },
    handleEdit(val) {
      this.form = { ...val, password: '', passwordConfirm: '' }
      this.isShowEditDialog = true
    },
    handleSubmitForm() {
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      Promise.resolve()
        .then(() => {
          if (this.form.id) {
            return this.$http.put(`/boom-center-admin-service/sysAdmin/sysUser`, this.form)
          } else {
            if (this.form.password !== this.form.passwordConfirm) {
              return Promise.reject({ msg: '两次输入的密码不一致' })
            } else {
              return this.$http.post('/boom-center-admin-service/sysAdmin/sysUser', { ...this.form, email: 'null' })
            }
          }
        })
        .then(() => {
          this.$message.success('操作成功')
          this.isShowEditDialog = false
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      this.$http
        .get('/boom-center-admin-service/sysAdmin/sysUser', {
          params: {
            ...this.filter,
            // ...this.page
            page: this.currentPage,
            size: this.pageSize
          }
        })
        .then(res => {
          this.list = res.list
          this.total = res.total
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted() {
    this.getRoleList()
  }
}
</script>

<style lang="less" scoped>
.userAvatar {
  width: 80px;
  height: 80px;
}
.uploadIcon {
  width: 80px;
  height: 80px;
  border: 1px dashed @color-border;
  .flexCenter;
  cursor: pointer;
  font-size: 26px;
  &:hover {
    border: 1px dashed @color-primary-select;
  }
}
</style>
